/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import LinkImage from "../images/link-preview.png"

function SEO({ description, lang, meta, title }) {
    const metaDescription = `Sidebrain is a fresh kind of productivity app built for makers who'd like to do more & be calmer. We can argue that it's a symbiotic partner to creators.`

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={`Sidebrain • Uniquely fresh kind of productivity app for makers`}
            // titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: "website",
                },
                {
                    property: `og:url`,
                    content: "https:/www.sidebrain.app",
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:image`,
                    content: `https://www.datocms-assets.com/34566/1604145895-link-preview.png`, //image url here
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: `@getsidebrain`,
                },
                {
                    name: `twitter:url`,
                    content: "https:/www.sidebrain.app",
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:text:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:image`,
                    content: `https://www.datocms-assets.com/34566/1604145895-link-preview.png`, //image url here
                },
            ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default SEO
